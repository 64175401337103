import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'

const Content = ({ location }) => {
    return (
        <Layout>
            <Seo
                title="Privacy - South Lane"
                desc="South Lane takes the privacy of your information seriously. This Privacy Policy applies to the southlane.com website."
                pathname={location.pathname}
            />
            <div className="content-narrow">
                <div className="heading-container heading-container--privacy align-center">
                    <h1 className="h2">Privacy Policy</h1>
                    <p className="p-l">Last updated January 1, 2020</p>
                </div>

                <div className="common-content-block">
                    <h6>Introduction</h6>
                    <p>
                        South Lane Pte. Ltd. (“South Lane”, “we” or “us”) takes
                        the privacy of your information seriously. This Privacy
                        Policy applies to the southlane.com website (the
                        “Website”) and governs data collection, processing and
                        usage in compliance with the Personal Data Protection
                        Act 2012 (No. 26 of 2012) of Singapore (“PDPA”). By
                        using the Website, you consent to the data practices
                        described in this statement.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>
                        Information collected from all visitors to our Website
                    </h6>
                    <p>
                        We will obtain personal data about you when you visit
                        us. When you visit us, we may monitor the use of this
                        Website through the use of cookies and similar tracking
                        devices. For example, we may monitor the number of times
                        you visit our Website or which pages you go to. This
                        information helps us to build a profile of our users.
                        Some of this data will be aggregated or statistical,
                        which means that we will not be able to identify you
                        individually.
                    </p>
                    <p>
                        This Privacy Policy applies to all visitors to our
                        Website.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>
                        Additional Personal Information that may be collected
                    </h6>
                    <p>South Lane may collect and process:</p>
                    <ol>
                        <li>
                            Personally identifiable information, such as:
                            <ol type="a">
                                <li>
                                    Your e-mail address and name, when you
                                    contact us;
                                </li>
                                <li>
                                    Services. These details may include your
                                    name, phone number, email, and the purpose
                                    of your query; (“Personal Information”)
                                </li>
                            </ol>
                        </li>
                        <li>
                            Information about your computer hardware and
                            software when you use our Website. The information
                            can include: your IP address, browser type, domain
                            names, access times and referring website addresses.
                            This information is used by South Lane for the
                            operation of the Services, to maintain quality of
                            the Services, and to provide general statistics
                            regarding use of the southlane.com Website.
                        </li>
                    </ol>
                </div>

                <div className="common-content-block">
                    <h6>Use of Personal Information</h6>
                    <p>South Lane uses the collected information:</p>
                    <ol>
                        <li>
                            To operate the Website and deliver the Services;
                        </li>
                        <li>
                            To process, and where necessary, respond to your
                            application, enquiry or request;
                        </li>
                        <li>To gather customer feedback;</li>
                        <li>
                            To inform or update you of other products or
                            services available from South Lane and its
                            affiliates, where you have consented to be contacted
                            for such purposes;
                        </li>
                        <li>
                            To monitor, improve and administer the Website and
                            Services, and to provide general statistics
                            regarding user of the Website;
                        </li>
                        <li>
                            To update you on changes to the Website and
                            Services.
                        </li>
                    </ol>
                </div>

                <div className="common-content-block">
                    <h6>Non-disclosure</h6>
                    <p>
                        South Lane does not sell, rent, lease, or release your
                        Personal Information to third parties. South Lane may,
                        from time to time, contact you on behalf of external
                        business partners about a particular offering that may
                        be of interest to you. In those cases, your unique
                        Personal Information is not transferred to the third
                        party without your explicit consent. In addition, South
                        Lane may share data with trusted partners to help us
                        perform customer relationship management, statistical
                        analysis, send you email or provide customer support.
                        All such third parties are prohibited from using your
                        personal information except to provide these services to
                        South Lane, and they are required to maintain the
                        confidentiality of your Personal Information in
                        accordance with the standards and requirements of the
                        PDPA.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>Disclosure of Personal Information</h6>
                    <p>
                        South Lane will disclose or share your Personal
                        Information, without notice, only if required to do so
                        by law or in the good faith belief that any such action
                        is necessary to: (a) comply with any legal requirements
                        or comply with legal process served on South Lane or the
                        Website; (b) protect and defend the rights or property
                        of South Lane; and (c) act under exigent circumstances
                        to protect the personal safety of users of
                        southlane.com, or the general public.
                    </p>
                    <p>
                        We may disclose your Personal Information to third
                        parties: (a) in the event that we sell or buy any
                        business or assets, in which case we may disclose your
                        personal data to the prospective seller or buyer of such
                        business or assets; and (b) if southlane.com or
                        substantially all of its assets are acquired by a third
                        party, in which case personal data held by it about its
                        customers will be one of the transferred assets.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>Use of cookies</h6>
                    <p>
                        The Website uses “cookies” to help you personalize your
                        online experience. A cookie is a text file that is
                        placed on your hard drive by a web page server. Cookies
                        cannot be used to run programs or deliver viruses to
                        your computer. Cookies are uniquely assigned to you, and
                        can only be read by a web server in the domain that
                        issued the cookie to you.
                    </p>
                    <p>
                        Cookies on the Website may be used to ensure a smooth
                        user experience, perform analytics, and for showing
                        relevant advertisements. Please note that third parties
                        (such as analytics software) may also use cookies, over
                        which we have no control. These cookies are likely to be
                        analytical/performance cookies or targeting cookies. The
                        Website uses Google Analytics. Please refer to
                        http://www.google.com/policies/privacy/partners to find
                        out more about how Google uses data when you use our
                        website and how to control the information sent to
                        Google.
                    </p>
                    <p>
                        Most web browsers automatically accept cookies, but you
                        can usually modify your browser setting to decline
                        cookies if you prefer. If you choose to decline cookies,
                        you may not be able to access all or parts of our
                        Website or to fully experience the interactive features
                        of the South Lane services or websites you visit.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>Security of your Personal Information</h6>
                    <p>
                        We strive to maintain the safety of your Personal
                        Information. Any information you share via the Website
                        will be encrypted using SSL technology. Unfortunately,
                        no internet-based service is completely secure. Although
                        we will do our best to protect your personal data, we
                        cannot guarantee the security of your data transmitted
                        to our site; any transmission is at your own risk. Once
                        we have received your information, we will use strict
                        procedures and security features to try to prevent
                        unauthorised access.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>
                        Access to, updating, and non-use of your Personal
                        Information
                    </h6>
                    <p>
                        Subject to the exceptions referred to in section 21(2)
                        of PDPA, you have the right to request a copy of the
                        information that we hold about you. If you would like a
                        copy of some or all of your Personal Information, please
                        send an email to hello@southlane.com.
                    </p>
                    <p>
                        We want to ensure that your Personal Information is
                        accurate and up to date. If any of the information that
                        you have provided to South Lane changes, for example if
                        you change your email address, name or contact number,
                        please let us know the correct details by sending an
                        email to hello@southlane.com. You may ask us, or we may
                        ask you, to correct information you or we think is
                        inaccurate, and you may also ask us to remove
                        information which is inaccurate.
                    </p>
                    <p>
                        You have the right to ask us not to collect, use,
                        process, or disclose your Personal Information in any of
                        the manner described herein. We will usually inform you
                        (before collecting your Personal Information) if we
                        intend to use your Personal Information for such
                        purposes or if we intend to disclose your Personal
                        Information to any third party for such purposes. You
                        can give us notice of your intention to halt the
                        collection, use, processing, or disclosure of your
                        Personal Information at any time by contacting us at
                        hello@southlane.com.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>Links to other websites</h6>
                    <p>
                        Our Website may contain links to other websites. This
                        Privacy Policy only applies to this website so when you
                        link to other websites you should read their own privacy
                        policies.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>Changes to this statement</h6>
                    <p>
                        South Lane will occasionally update this Privacy Policy
                        to reflect customer feedback. South Lane encourages you
                        to periodically review this Privacy Policy to be
                        informed of how South Lane is protecting your
                        information.
                    </p>
                </div>

                <div className="common-content-block">
                    <h6>Contact information</h6>
                    <p>
                        South Lane welcomes your comments regarding this Privacy
                        Policy. If you believe that southlane.com has not
                        adhered to this Privacy Policy, please contact us at
                        hello@southlane.com.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
